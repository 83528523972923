import React from "react";
import styled from "@emotion/styled";
import { Title } from "./Text";

const GalleryContainer = styled.div`
  text-align: center;
`;

const TextContainer = styled.div`
  margin-top: 1rem;
  font-size: 20px;
`;

function Registry() {
  return (
    <GalleryContainer>
      <Title>Registry</Title>
      <TextContainer>
        <p>
          Thank you for your generosity and for celebrating this special milestone
          with us!
        </p>
      </TextContainer>
      <TextContainer>
        <a
          href="https://www.crateandbarrel.com/gift-registry/michaela-consolini-and-anthony-motto/r7281289"
          rel="noreferrer"
          target="_blank"
        >
          Crate and Barrel Registry
        </a>
      </TextContainer>
      <TextContainer>
        <a
          href="https://www.amazon.com/wedding/registry/2B6JEN4EEL53Y"
          rel="noreferrer"
          target="_blank"
        >
          Amazon Registry
        </a>
      </TextContainer>
    </GalleryContainer>
  );
}

export default Registry;
